import React from 'react'

import Layout from '../components/layout/layout'
import SEO from '../components/seo'

import RequestInviteForm from '../components/requestinvite/requestinviteForm'

import '../components/requestinvite/requestinvite.css'

const RequestInvitePage = () => (
  <Layout>
    <SEO title="Request an Invite" />
    <h1>
      Request an Invite
    </h1>
    <div className="request-invite-form-outer-container">
      <RequestInviteForm />
    </div>
  </Layout>
)

export default RequestInvitePage
