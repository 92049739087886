import React, { useState } from 'react'

import isEmpty from 'lodash/isEmpty'
import validator from 'validator'

import axios from 'axios'

import Modal from 'react-modal'

import { Formik } from 'formik'

Modal.setAppElement('#___gatsby')

const modalStyles = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.58)"
  },
  content: {
    position: "relative",
    top: "50%",
    bottom: "50%",
    left: "auto",
    right: "auto",
    maxWidth: 480,
    margin: "auto",
    padding: 24,
    border: 0
  }
}

const RequestInvite = () => {
  const [modalOpen, setModalOpen] = useState(false)

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        usa: false,
        iphone: false,
        referral: '',
        message: '',
      }}
      validate={values => {
        const errors = {}
        if (isEmpty(values.name)) {
          errors.name = 'Required'
        }

        if (isEmpty(values.email)) {
          errors.email = 'Required'
        } else if (!validator.isEmail(values.email)) {
          errors.email = 'Not a valid email address'
        }

        if (isEmpty(values.referral)) {
          errors.referral = 'Required'
        }

        if (isEmpty(values.message)) {
          errors.message = 'Required'
        }

        return errors
      }}
      onSubmit={async (values, { resetForm, setErrors }) => {
        try {
          await axios.post(`${process.env.API_URL}/requestinvite`, values)
          openModal()
          resetForm()
        } catch (err) {
          if (isEmpty(err.response)) {
            setErrors({ form: 'There was an error. Please try again later!' })
          } else {
            const {
              errors,
            } = err.response.data

            setErrors(errors)
          }
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <div>
          <Modal
            isOpen={modalOpen}
            onRequestClose={closeModal}
            style={modalStyles}
          >
            <div>
              {'Thanks for requesting an invite! You\'ll hear from us soon.'}
            </div>
          </Modal>
          <form
            className="request-invite-form"
            onSubmit={handleSubmit}
          >
            <input
              name="name"
              placeholder="Name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.name && errors.name && (
              <div className="error-container">{errors.name}</div>
            )}
            <input
              name="email"
              placeholder="Email"
              type="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            {touched.email && errors.email && (
              <div className="error-container">{errors.email}</div>
            )}
            <div className="checkbox-container">
              <input
                type="checkbox"
                id="usa"
                name="usa"
                value={values.usa}
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.usa}
              />
              <label htmlFor="usa">
                I live in the USA *
              </label>
            </div>
            <div className="checkbox-container">
              <input
                type="checkbox"
                id="iphone"
                name="iphone"
                value={values.iphone}
                onChange={handleChange}
                onBlur={handleBlur}
                checked={values.iphone}
              />
              <label htmlFor="iphone">
                I have an iPhone *
              </label>
            </div>
            <div className="info">
              * Relatable is currently only available in the USA and on the iPhone.
            </div>
            <div className="spacer" />
            <textarea
              name="referral"
              placeholder="How did you hear about Relatable?"
              value={values.referral}
              onChange={handleChange}
              onBlur={handleBlur}
              rows={5}
            />
            {touched.referral && errors.referral && (
              <div className="error-container">{errors.referral}</div>
            )}
            <textarea
              name="message"
              placeholder="Why do you want to join?"
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
              rows={5}
            />
            {touched.message && errors.message && (
              <div className="error-container">{errors.message}</div>
            )}
            {errors.form && (
              <div
                className="error-container"
                style={{ marginTop: 8, marginBottom: 0 }}
              >{errors.form}</div>
            )}
            <div className="button-container">
              <button type="submit" disabled={isSubmitting}>
                Request Invite
              </button>
            </div>
          </form>
        </div>
      )}
    </Formik>
  )
}

export default RequestInvite
